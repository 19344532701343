<template>
  <item-form :is-visible="history_form_visibility">
    <template #hide-trigger>
      <button class="p-2 px-4 bg-yellow-400 form__trigger-hide" @click="hide()">
        <fa-icon icon="times"></fa-icon>
      </button>
    </template>

    <template #default>
      <p class="mb-6 text-xl uppercase tracking-wider">formulir sejarah</p>
      <form @submit.prevent="submit()" class="">
        <!-- date of event -->
        <div class="col-span-2">
          <label for="history-date">Tanggal</label>
          <datetime
            id="history-date"
            title="Tanggal Peristiwa"
            placeholder="Tanggal Peristiwa"
            class="custom-datepicker"
            :use12-hour="false"
            type="date"
            v-model="form.happened_at"
            :auto="true"
          ></datetime>
        </div>

        <!-- history event -->
        <div>
          <label for="history-event">Peristiwa</label>
          <textarea
            placeholder="Deskripsi singkat peristiwa"
            id="history-event"
            class="w-full block"
            v-model="form.description"
          ></textarea>
        </div>

        <div class="">
          <button type="submit" class="button mt-6 w-full --primary">
            <fa-icon icon="check"></fa-icon>
            <span class="ml-2">Simpan</span>
          </button>
        </div>
      </form>
    </template>
  </item-form>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _clone from "lodash/clone";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

export default {
  data() {
    return {
      form: {
        id: null,
        happened_at: null,
        description: null,
      },
      formDefault: {},
    };
  },
  methods: {
    hide() {
      this.form = _clone(this.formDefault);
      this.HIDE_HISTORY_FORM();
      this.UNSET_SELECTED_HISTORY();
    },
    async submit() {
      // store when id is null
      if (this.form.id === null) {
        await this.storeHistory(this.form);
      } else {
        await this.updateHistory(this.form);
      }
      this.hide();
    },
    ...mapActions("school_profile", ["storeHistory", "updateHistory"]),
    ...mapMutations("school_profile", [
      "HIDE_HISTORY_FORM",
      "UNSET_SELECTED_HISTORY",
    ]),
  },
  computed: {
    ...mapGetters("school_profile", [
      "history_form_visibility",
      "selected_history",
    ]),
  },
  watch: {
    selected_history(val) {
      this.form = val === null ? _clone(this.formDefault) : _clone(val);
    },
  },
  async created() {
    this.formDefault = _clone(this.form);
    Settings.defaultLocale = "id";
  },
  beforeDestroy() {},
  components: {
    ItemForm: () => import("@/components/Common/ItemForm.vue"),
    Datetime,
  },
  name: "HistoryTreeForm",
};
</script>